/* Project Container */
.projects-main {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    margin-left: 10%;
    margin-right: 10%;
}

@media(max-width:1012px){
    .projects-main{
        margin-left: 0;
        margin-right: 0;
    }
}

/* Individual Project */
.projects {
    width: 30%;
    max-width: 300px;
    min-width: 250px;
    height: auto;
    background-color: rgb(170, 185, 234);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    position: relative; /* Make sure it stays relative to its parent */
    overflow: hidden; /* Hide the .project-detail when not hovered */
  }
  
  .project-detail {
    display: none; /* Hide by default */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(170, 185, 234); 
    padding: 10px; 
    box-sizing: border-box; 
    justify-content: center;
    text-align: center;
    align-items: center;
    padding-top: 20%;
    transform: translateY(100%); /* Start off-screen */
    transition: transform 0.3s ease-in-out; /* Smooth animation */
    opacity: 0; /* Hidden initially */
  }
  
  .projects:hover .project-detail {
    display: block; /* Ensure it's visible on hover */
    transform: translateY(0); /* Move into view */
    opacity: 1; /* Fade in */
  }

  .project-detail h3{
    color: rgb(50, 45, 45);
    margin-bottom: 10px;
    font-weight: 800;
  }

  .project-detail p{
    color: rgb(50, 45, 45);
    margin-bottom: 10px;
  }
  .project-detail button{
    background-color: rgb(50, 45, 45);
    font-size: 16px;
    width: 130px;
    height: 30px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    font-family: 'Times New Roman', Times, serif;
  }
 

  .project-detail button:hover{
    width: 135px;
    height: 32px;
  }

.dark .projects {
    background-color: rgb(196, 210, 255);
}

@media(max-width:1012px){
    .projects-main{
        margin-left: 0;
        margin-right: 0;
    }
    .projects{
        height: auto;
    }
}

.projects:hover {
    transform: scale(1.05);
}

/* Project Image */
.upper img {
    margin: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Description Section */
.down {
    padding: 3% 10px;
}

.down h3 {
    font-size: 24px;
}

.down p {
    color: rgb(86, 81, 81);
    font-weight: 500;
    font-size: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    margin-bottom: 15px;
}

/* Button Styling */
.down button {
    width: 40%;
    height: 25px;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    border-radius: 20px;
    border-width: 1px;
    border-color: none;
    cursor: pointer;
    color: whitesmoke;
    background-color: rgb(86, 81, 81);
    transition: transform 0.3s ease-in-out;
}

.down button:hover {
    transform: scale(1.05);
    font-weight: 600;
}

/* Tags Styling */
.down div {
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
}

.down div p {
    width: 50px;
    height: 13px;
    text-align: center;
    border-radius: 20px;
    font-size: 9px;
    background-color: rgb(195, 194, 194);
    color: black;
    padding-top: 1.7px;
    font-weight: 600;
}

@media(max-width:675px){
    .projects {
        max-width: 280px;
        width: auto;
    }
   
}

/* Responsive Styling for Small Screens */
@media(max-width:550px){
    .projects {
        max-width: 320px;
        width: auto;
    }
    .down h3 {
        font-size: 22px;
    }
}

/* Project Icons */
.project-ico {
    margin-top: 10px;
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    gap: 10px;
}

.project-ico img {
    width: 25px;
    height: 25px;
    border-radius: 2px;
    mix-blend-mode: multiply;
}
