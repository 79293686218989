* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: sans-serif; */
}

.container {
    /* width: 100vw; */
    min-height: 100vh;
    background: #ced8ff;
    transition: 0.5s;
}

.container.dark {
    background: #222;
}
