/* .text-preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .text-preloader-text {
    font-size: 90px;
    color: #333;
    animation: fadeInOut 10s linear infinite; /
  }
  
  @keyframes fadeInOut {
    0% , 20%, 40%, 60% {
      opacity: 0.2; 
    }
    10%,30% ,50%, 70% {
      opacity: 1;
    }

   
    80% {
      opacity: 0.5; 
    }
    90%{
        opacity: 0.3;
        
    }

    95%{
        opacity: 0.2;
    }
  }
   */


   .text-preloader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222; /* Change background color to #222 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    opacity: 0.2;
    transition: opacity 0.5s ease;
    opacity: .7;
  }
  
  .text-preloader-overlay.hidden {
    opacity: 1;
    pointer-events: none;
  }
  
  .text-preloader-text {
    font-size: 70px;
    color: rgba(255, 255, 255, 0.61);
  }
  
  @media (max-width: 500px) {
    .text-preloader-text {
      font-size: 50px;
      color: rgba(255, 255, 255, 0.61);

    }
  }
  