@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');


.service{
    margin: 50px 10% 0 10%;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    }
   
    .services, .services-1{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 40px;

    }

   .services-contact, .services-contact a{
    color: #222;
   }

   .dark  .services-contact, .dark .services-contact a{
    color:rgb(138, 184, 236);
   }
    
    .services .left {
        min-width: 130px;
        color: #222;
        font-size: 25px;
        margin-top: 0;
        padding-top: 0;
        display: flex;
        justify-content: flex-start; 
        align-items: center;
        flex-direction: column; 
        height: 100%; 
        font-weight: 700;
        font-family: "Orbitron", sans-serif;
        font-weight: 600;
    }

    .dark .services .left{
        color: rgb(138, 184, 236);
    }
    
    .services   .right{
        text-align: left;
        max-width: 60%;
        background-color: rgb(138, 184, 236);
        padding-top: 15px;
        padding-left: 15px;
        font-size: 18px;
        border-radius: 5px;
        padding-right: 5px;
        padding-bottom: 5px;
        font-weight: 600;
        color: #222;
    }

    .dark .services .right{
        background-color: #3a3939;
        color: #ced8ff;
    }

    
    
    @media(max-width: 750px){
        .BenefitSec{
            margin: 0 5% 0 5%;
        }
    
        .services  .left{
            font-size: 22px;
        }
    }
    
    @media(max-width: 600px){
    
        .services{
            flex-direction: column;
        }
    
        .services  .left{
            font-size: 22px;
        }
    
        .services  .right{
            font-size: 15px;
            max-width: 100%;
        }
    
        .services-1{
            flex-direction: column-reverse;
        }
    }