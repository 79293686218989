.parallax {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; /* Ensures it spans the full width of the viewport */
}

.scroller {
  display: inline-block;
  white-space: nowrap;
}

.scroller span {
  display: inline-block;
  font-size: 2rem;
  margin-right: 2rem; /* Adds spacing between text items */
  color: #222; /* Replace with your desired text color */
}

.dark .scroller span{
  color: whitesmoke;
}